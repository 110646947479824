import React from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import { getData } from '../../utils/utilityFunctions';

const IntroBlock = styled.div`
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  // background: #2867e5;
  color: #333;
  text-transform: uppercase;
  padding: 10px;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: clamp(20px, 3vw, 28px);
    font-weight: 300;
    margin: 0;
  }
  h3 {
    font-size: 18px;
    font-weight: 400;
  }
  .ybm {
    font-size: clamp(30px, 4.5vw, 65px);
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
  }
`;
const EtffContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

  font-family: 'Poppins', Arial, san-serif;
  width: 100%;
  .featuredText {
    text-align: center;
    font-size: clamp(20px, 3vw, 28px);
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }

  .clickButton {
    box-sizing: border-box;
    width: 44%;
    max-width: 400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f08c34;
    color: #fff;
    padding: 10px 8px;
    border-radius: 50px;
    margin: -20px auto 30px;
    box-shadow: 0 5px 8px #555;
    border: none;
    &:hover {
      cursor: pointer;
    }

    h2 {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      margin: 0;
      font-size: clamp(24px, 3.75vw, 30px);
      font-weight: 400;
      text-align: center;
    }
    @media (max-width: 801px) {
      width: 100%;
    }
  }

  .logoContainer {
    width: calc(100% - 20px);
    max-width: 500px;
    margin: 20px auto;
  }
  .buttonContainer {
    width: calc(100% - 20px);
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    @media (max-width: 801px) {
      flex-direction: column;
    }
  }
  .divider {
    width: calc(100vw - 30px);
    max-width: 450px;
    margin: 0 auto 20px;
    padding-top: 20px;
    border-bottom: 1px solid #000;
  }

  .iframeContainer {
    aspect-ratio: 16/9;
    width: calc(100vw - 30px);
    max-width: 700px;
    margin: 0 auto;
    iframe {
      width: 100%;
      height: 100%;
    }
    h1 {
      text-align: center;
    }
  }
  .contentContainer {
    width: calc(100vw - 30px);
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    h2 {
      color: #055241;
    }
    .header1 {
      margin-bottom: 2px;
    }
    .header2 {
      margin: 30px 0 2px;
    }
    p {
      font-weight: 600;
      font-size: 14px;
    }
    .p1 {
      margin: 2px 0 20px;
    }
    .p2 {
      margin: 2px 0 20px;
    }
    .p2 {
      margin: 20px 0 20px;
    }
  }
  .lowerContainer {
    width: calc(100vw - 30px);
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    p {
      font-size: 16px;
    }
    .reviewContainer {
      width: 100%;
      margin: 20px auto;
      img {
        width: 100%;
      }
    }
  }
  .footerImages {
    width: calc(100vw - 30px);
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .equalContainer {
      width: 100%;
      max-width: 75px;
      img {
        width: 100%;
      }
    }
    .bbbContainer {
      width: 100%;
      max-width: 200px;
      img {
        width: 100%;
      }
    }
  }
  .footerLinks {
    width: calc(100vw - 30px);
    max-width: 1160px;
    display: flex;
    justify-content: center;
    margin: 0 auto 25px;
    align-items: center;
    p,
    a {
      font-size: 12px;
      margin-bottom: 0;
      border-right: 1px solid #000;
      margin-left: 10px;
      padding-right: 10px;
      @media (max-width: 801px) {
        font-size: 11px;
        margin-left: 7px;
        padding-right: 7px;
      }
    }
    a {
      color: #1caefb;
      text-decoration: none;
    }

    .finalLink {
      border-right: none;
    }
  }
`;

const EtffThankyou = () => {
  const trackConversion = trackingUrl => {
    if (trackingUrl && trackingUrl !== '[TRACKING_LINK]') {
      const trackingPixel = document.createElement('img');
      trackingPixel.src = trackingUrl;
      trackingPixel.width = 1;
      trackingPixel.height = 1;
      trackingPixel.style.display = 'none';
      document.body.appendChild(trackingPixel);
    }
  };

  const openUrl = url => {
    trackConversion(
      'https://trkthat5.com/?OO=0uvpIS3yVr8RPrTQOL%2bVRecV2pp1jy6g&s1='
    );
    window.open(url, '_blank');
  };

  const handleChatButton = e => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.BOTSPLASH_APP_PARAMS = {
        visitor: {
          firstName: getData('first_name') || '',
          lastName: getData('last_name') || '',
          email: getData('email') || '',
          phoneNumber: getData('phone_primary') || '',
          state: getData('state') || '',
          zipCode: getData('zipcode') || '',
          custom: { loanpurpose: getData('loan_purpose') || '' },
        },
      };
      window.$botsplash.open({ hideOnClose: true });
    }
  };

  return (
    <>
      <EtffContainer>
        <IntroBlock>
          <h1>
            <span className="ybm">You’ve been matched!</span>
          </h1>
        </IntroBlock>

        <p className="featuredText">With our featured provider:</p>
        <div className="logoContainer">
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/tffLogo.png"
            alt="TTF Logo"
          />
        </div>
        <div className="buttonContainer">
          <button
            type="button"
            onClick={() =>
              openUrl(
                'https://www.holen1app.com/homehub/signup/TFEAGER@TOPFLITEFINANCIAL.COM'
              )
            }
            className="clickButton"
          >
            <h2>Apply Now!</h2>
          </button>
          <button
            id='chat-button"'
            type="button"
            className="clickButton"
            onClick={e => handleChatButton(e)}
          >
            {' '}
            <h2>Live Chat</h2>
          </button>
        </div>
        <div className="divider" />
        <div className="contentContainer">
          <h2 className="header1">
            At Top Flite Financial, you’re more than a credit score
          </h2>
          <p className="p1">
            When you work with Top Flite, it won’t be long before you realize we
            are not your typical lender.
          </p>
          <h2 className="header2">
            In fact, we are known for approving loans that many other large
            banks and mortgage lenders turn down.
          </h2>
          <p className="p2">
            As one of the few lenders in the country that works with credit
            scores down to 500, we pride ourselves on giving homeowners access
            to the equity they have earned.{' '}
          </p>
          <p className="p3">
            Looking to consolidate debt? Then a cash-out refinance or home
            equity line of credit (HELOC) through Top Flite may be the way to
            go. No matter your financial situation, we are likely to have a
            program that fits your unique needs.
          </p>
        </div>
        <div className="divider" />
        <div className="iframeContainer">
          <h1>WHY CHOOSE US?</h1>
          <iframe
            src="https://www.youtube.com/embed/dPoApx-xe34"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="divider" />
        <div className="lowerContainer">
          <p>
            We are one of the highest-rated mortgage lenders in the entire
            country.
          </p>
          <p>See what our customers have to say:</p>
          <a
            href="https://www.google.com/search?q=Top+Flite+Financial+-+Consumer+Direct+-+Reviews&rlz=1C1CHBF_enUS794US795&ei=1_HpYYbPG_OqptQPz-aj2As&ved=0ahUKEwjGltezv8H1AhVzlYkEHU_zCLsQ4dUDCA4&uact=5&oq=Top+Flite+Financial+-+Consumer+Direct+-+Reviews&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEM0CMgUIABDNAjIFCAAQzQIyBQgAEM0CMgUIABDNAjoKCC4QxwEQrwEQQzoECAAQQzoLCAAQgAQQsQMQgwE6DgguEIAEELEDEMcBEKMCOgsILhCABBDHARCjAjoRCC4QgAQQsQMQgwEQxwEQ0QM6BggAEAoQQzoNCC4QsQMQxwEQ0QMQQzoLCC4QgAQQxwEQrwE6CAguEIAEELEDOggIABCABBCxAzoNCC4QsQMQxwEQrwEQQzoKCAAQsQMQgwEQQzoFCAAQgAQ6BQguEIAEOgUIIRCgAToKCC4QxwEQrwEQDToECAAQDToGCAAQFhAeOgIIJjoFCCEQqwJKBAhBGABKBAhGGABQAFi8iAFgwZEBaAxwAngAgAGAAYgB6iiSAQQ1My45mAEAoAEBwAEB&sclient=gws-wiz"
            target="_blank"
            rel="noreferrer"
            className="redirectContainer"
          >
            <div className="reviewContainer">
              <img
                src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/reviewImage.png"
                alt="Review Img"
              />
            </div>
          </a>
          <p>
            Our professionals have received your inquiry. Please expect a call,
            text and email from a mortgage pro at any moment
          </p>
        </div>
        <div className="footerImages">
          <div className="bbbContainer">
            <img
              src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/bbbImage.png"
              alt="BBB logo"
            />
          </div>
          <div className="equalContainer">
            <img
              src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/equalHousing.png"
              alt="equal housing logo"
            />
          </div>
        </div>
        <div className="footerLinks">
          <p>Copyright © 2022 </p>
          <p>Top Flite Financial, Inc.</p>
          <p>NMLS# 4181</p>
          <p>Branch NMLS#1035759</p>
          <a
            href="https://www.nmlsconsumeraccess.org/"
            target="_blank"
            rel="noreferrer"
          >
            NMLS Consumer Access Portal
          </a>
          <a
            href="https://topflitecd.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://topflitecd.com/licensing/"
            target="_blank"
            rel="noreferrer"
            className="finalLink"
          >
            Licensing
          </a>
        </div>
      </EtffContainer>
    </>
  );
};

export default withThankyouWrapper(EtffThankyou);
